import { useApi, useWorkspace } from '~/contexts';
import { useField, useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { colors } from '~/styles';
import AutoComplete from './AutoComplete';
import BillableIcon from './BillableIcon';
import FieldControl from './FieldControl';
import styled from 'styled-components';
import Tooltip from './Tooltip';

const Display = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;

  > div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

function ProjectRoleSelect({
  value,
  project,
  assignedMemberId,
  initialValue,
  exclude,
  onSearch,
  activeOnly = true,
  ...props
}) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);

  // Since useEffect/useCallback only performs shallow equality, prevent firing the search function for deep-equal arrays
  const excludeRef = useRef(exclude);
  if (JSON.stringify(exclude) === JSON.stringify(excludeRef.current)) exclude = excludeRef.current;

  const handleSearch = useCallback(
    async (q) => {
      if (onSearch) {
        return onSearch(q);
      }

      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .roles()
        .get({
          q,
          isActive: activeOnly ? true : undefined,
          include: initialValue ? initialValue.id : undefined,
          assignedMemberId: assignedMemberId || undefined,
          exclude,
          size: 1000,
        });

      return data;
    },
    [workspace.id, initialValue, exclude, api, project.id, activeOnly, onSearch, assignedMemberId],
  );

  useEffect(() => {
    (async () => {
      const data = await handleSearch('');
      if (!isMounted.current) return;
      setDefaultOptions(data);
    })();
  }, [handleSearch, isMounted]);

  return (
    <AutoComplete
      onSearch={handleSearch}
      value={value}
      displayText={value?.name ?? ''}
      defaultOptions={defaultOptions}
      renderValue={(value) => value.name}
      renderOption={(option) => (
        <Display>
          <BillableIcon value={option.isActuallyBillable} />
          <Tooltip message={option.name}>
            <span style={!option.isActive ? { color: colors.grey40 } : undefined}>{option.name}</span>
          </Tooltip>
        </Display>
      )}
      {...props}
    />
  );
}

function FieldProjectRoleSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <ProjectRoleSelect {...field} {...props} />
    </FieldControl>
  );
}

export default ProjectRoleSelect;
export { FieldProjectRoleSelect };
